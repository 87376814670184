import React, { useContext, useEffect, useState } from 'react';
import { delay, isEmpty } from 'lodash';
import Fade from 'react-reveal/Fade';
import { Modal, ModalCentered, ModalWrapper, SuccessModalWrapper } from './Styled';
import { ContactContext } from '../../internal/context/ContactContext';
import { contactSchema } from './schema';


const ContactForm = () => {
  const { showModal, setShowModal, sendMessage, loading } = useContext(ContactContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const [message, setMessage] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    interests: [],
  });
  const [errors, setErrors] = useState({});


  useEffect(() => {
    if (showSuccess) {
      delay(() => {
        setShowSuccess(false);
      }, 3500);
    }
  }, [showSuccess]);

  useEffect(() => {
    return () => {
      setMessage({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        interests: [],
      });
      setErrors({});
    }
  }, [])

  const validateAndSendMessage = async () => {
    try {
      const result = contactSchema.validateSync(message, { abortEarly: false });
      await sendMessage(result);
      setShowSuccess(true);
    } catch (e) {
      if (e.inner) {
        setErrors(e.inner.reduce((acc, { path, errors }) => {
          acc[path] = errors.join(', ');
          return acc;
        }, {}));
      }
    }
  }
  const handleErrors = () => {
    if (!isEmpty(errors)) {
      try {
        const result = contactSchema.validateSync(message, { abortEarly: false });
      } catch (e) {
        if (e.inner) {
          setErrors(e.inner.reduce((acc, { path, errors }) => {
            acc[path] = errors.join(', ');
            return acc;
          }, {}));
        }
      }
    }
  }

  const onChange = (property) => (e) => {
    message[property] = e.target.value;
    setMessage({ ...message });
    handleErrors();
  }

  const onChangeCheckBox = (property) => (e) => {
    const options = message[property];
    const value = e.target.value;
    const index = options.findIndex((op) => op === value);
    if (index > 0) {
      options.splice(index, 1);
    } else {
      options.push(value);
    }
    message[property] = options;
    setMessage({ ...message });
    handleErrors()
  }

  if (showSuccess) {
    return (
      <ModalCentered onClick={() => setShowSuccess(false)}>
        <Fade bottom duration={200}>
          <SuccessModalWrapper>
            <div>
              <div className='progress'>
                <span></span>
              </div>
              <h3>We recieved your request</h3>
              <p>Thanks for contacting use, one of our representative will be with you soon.</p>
              <button onClick={() => setShowSuccess(false)}>
                Close
              </button>
            </div>
          </SuccessModalWrapper>
        </Fade>
      </ModalCentered>
    )
  }

  if (!showModal) {
    return null;
  }

  return (
    <Modal>
      <Fade bottom duration={200}>
        <ModalWrapper>
          <div>
            <div className='contact-title'>
              <h1>We love to hear from you!</h1>
              <div className='close-button' onClick={() => setShowModal(false)}>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className='input-wrapper'>
              <p>Name</p>
              <input type='text' className={errors?.name ? 'error' : ''} onChange={onChange('name')} />
              <span>{errors?.name || ''}</span>
            </div>
            <div className='input-wrapper'>
              <p>Phone Number</p>
              <input type='text' className={errors?.phoneNumber ? 'error' : ''} onChange={onChange('phoneNumebr')} />
              <span>{errors?.phoneNumber || ''}</span>
            </div>
            <div className='input-wrapper'>
              <p>Email</p>
              <input type='text' className={errors?.email ? 'error' : ''} onChange={onChange('email')} />
              <span>{errors?.email || ''}</span>
            </div>
            <div className='input-wrapper'>
              <p>How can we help you?</p>
              <div className='input-checkbox'>
                <div>
                  <input type="checkbox" onChange={onChangeCheckBox('interests')} id="build_poduct" name="interest" value="build_product" />
                </div>
                <label for="build_poduct">I need to build a great product</label>
              </div>
              <div className='input-checkbox'>
                <div>
                  <input type="checkbox" onChange={onChangeCheckBox('interests')} id="enhance_product" name="interest" value="enhance_product" />
                </div>
                <label for="enhance_product">I need to enhance my existing product</label>
              </div>
              <div className='input-checkbox'>
                <div>
                  <input type="checkbox" onChange={onChangeCheckBox('interests')} id="business_proposal" name="interest" value="business_proposal" />
                </div>
                <label for="business_proposal">I have new business proposal</label>
              </div>
              <div className='input-checkbox'>
                <div>
                  <input type="checkbox" onChange={onChangeCheckBox('interests')} id="other" name="interest" value="other" />
                </div>
                <label for="other">Other</label>
              </div>
              <span>{errors?.interests || ''}</span>
            </div>
            <div className='input-wrapper'>
              <p>Tell us more about what you need</p>
              <textarea onChange={onChange('message')}></textarea>
            </div>
            {
              loading ? (
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              ) : (
                <button className='' onClick={validateAndSendMessage}>
                  Let's get started
                </button>
              )
            }
          </div>
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default ContactForm;